<template>
  <div>
    <b-card-code>
      <div class="mb-3 d-flex justify-content-between">
        <h6>
          Sample file :
          <a href="files/codereview_report.csv" download>
            Click here to download sample file.
          </a>
        </h6>

        <!-- <b-button @click="goBack()" size="sm" variant="primary">Back</b-button> -->
      </div>
      <validation-observer ref="CodeUploadForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="uploadReport">
          <b-form-group label="Add Existing Assets" label-for="Assets">
            <template #label>
              <span>Add Existing Asset</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="assets"
              rules="required"
            >
              <v-select
                v-model="asset_id"
                label="text"
                :options="asset_options"
                placeholder="--Search Asset--"
                @search="fetchOptions"
                autocomplete
                :reduce="(asset_id) => asset_id.value"
                state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!--Upload File -->
          <b-form-group label="Upload File" label-for="File">
            <template #label>
              <span>Upload File</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required|ext:csv"
            >
              <b-form-file
                v-model="code_report"
                :state="Boolean(code_report)"
                ref="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                v-on:change="handleFileUpload()"
              ></b-form-file>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="mt-1">
              Selected file: {{ code_report ? code_report.name : "" }}
            </div>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            :disabled="disable || asset_id === null || code_report === null || invalid"
            class="my-1"
            size="sm"
          >
            <span v-if="isloading"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Upload</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
    <b-card-code title="">
      <div
        class="d-flex justify-content-between align-items-center mb-1"
        v-if="items.length"
      >
        <h4>Uploads</h4>
      </div>
      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <div v-if="!loading">
        <b-table
          responsive
          id="uploadsTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          v-if="items.length"
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(asset_name)="data">
            <a
              class="text-truncate"
              @click="redirectToAssetScan(data.item.asset_id)"
            >
              {{ data.item.asset_name }}
            </a>
          </template>
          <template #cell(is_processed)="data">
            <b-badge variant="primary" v-if="data.item.is_processed == 0"
              >Queued</b-badge
            >
            <b-badge variant="warning" v-if="data.item.is_processed == 1"
              >In Progress</b-badge
            >
            <b-badge variant="success" v-if="data.item.is_processed == 2"
              >Processed</b-badge
            >
            <b-badge
              variant="danger"
              style="cursor: pointer"
              :title="data.item.errors"
              v-if="data.item.is_processed == 3"
              >Failed</b-badge
            >
          </template>
          <template #cell(stats)="data">
            <b-badge v-if="data.item.is_processed != 0" variant="success"
              >New Vulnerabilities: {{ data.item.new_vulns }}</b-badge
            ><br />
            <b-badge v-if="data.item.is_processed != 0" variant="secondary"
              >Vulnerabilities Retained: {{ data.item.dpl_vulns }}</b-badge
            >
          </template>
          <template #cell(activity_time)="data">
            <span>{{ data.item.created_at | formatdate }}</span>
          </template>
          <template #cell(actions)="data">
            <button
              @click="gotoDelete(data.item.report_id)"
              type="button"
              class="btn ml-auto btn-danger btn-sm"
            >
              Delete
            </button>
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Uploads Found</h3>
          </div>
        </div>
      </div>
      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :link-gen="linkGen"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="uploadsTable"
        use-router
      />
      <b-modal
        id="modal-danger"
        hide-footer
        modal-class="modal-danger"
        centered
        title="Delete.. !"
        v-model="openDeleteModal"
        size="lg"
      >
        <b-card-text> Do you really want to delete ? </b-card-text>
        <div class="d-flex justify-content-end">
          <b-button @click="deleteUpload()" variant="danger" size="sm">
            Delete
          </b-button>
        </div>
      </b-modal>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BTable,
  BPaginationNav,
  BBadge,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import moment from "moment";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BTable,
    BPaginationNav,
    BBadge,
    BSpinner,
    vSelect,
    BModal,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      org_id: this.$store.state.app.org_id,
      code_report: null,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "asset_name", label: "Asset" },
        { key: "is_processed", label: "Status" },
        { key: "stats", label: "Stats" },
        { key: "activity_time", label: "Upload Time" },
        { key: "uploaded_by", label: "Uploaded By" },
        { key: "actions", label: "Actions" },
      ],
      filter: [],
      showOrg: false,
      showGroup: false,
      isloading: false,
      disable: false,
      loading: false,
      asset_id: null,
      asset_options: [],
      cloud_upload_id: null,
      openDeleteModal: false,
    };
  },
  props: {
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  mounted() {},
  created: function () {
    if (this.tabIndex == 1) {
      this.$router.replace({ query: null });
      this.loadUploads();
    }
  },

  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadUploads();
    },
    tabIndex: function (newVal, oldVal) {
      if (newVal === 1) {
        this.$router.replace({ query: null });
        this.loadUploads();
      }
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    loadUploads: function () {
      this.loading = true;
      this.filter = [];
      let url =
        process.env.VUE_APP_BASEURL + "code-review/report-upload?query=true";
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      url = url + "&page=" + this.currentPage;

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },
    redirectToAssetScan(asset_id) {
      const assetId = asset_id;
      this.$router.push(`/assets/scan/${assetId}`);
    },
    refreshUploadForm() {
      this.asset_id = null;
      this.code_report = null;
      this.$refs.CodeUploadForm.reset();
    },
    uploadReport: function () {
      this.$refs.CodeUploadForm.validate().then((success) => {
        this.isloading = true;
        this.disable = true;
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Processing your request. Please wait!",
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
          let formData = new FormData();
          formData.append("file", this.code_report);
          formData.append("asset_id", this.asset_id);

          const options = {
            method: "POST",
            headers: { "content-type": "multipart/form-data" },
            data: formData,
            url:
              process.env.VUE_APP_BASEURL + "code-review/vulns/upload-report/",
          };
          var self = this;
          this.$http(options).then((res) => {
            self.loadUploads();
            if (res.data.status == "success") {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  autoHideDelay: 5000,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }

            this.isloading = false;
            this.disable = false;
            this.refreshUploadForm();
          });
        }
      });
    },
    handleFileUpload() {
      this.code_report = this.$refs.file.files[0];
    },
    gotoDelete(id) {
      this.openDeleteModal = true;
      this.cloud_upload_id = id;
    },
    deleteUpload() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "code-review/report-upload/" +
          this.cloud_upload_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.status === 204) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Deleted Successfully",
              autoHideDelay: 5000,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.openDeleteModal = false;
        this.loadUploads();
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>