<template>
  <b-tabs v-model="tabIndex">
    <b-tab
      title="Vulnerabilities"
    >
      <CodeReviewVuln :tabIndex="tabIndex" />
    </b-tab>
    <b-tab title="Upload Code Review">
      <UploadCodeReview :tabIndex="tabIndex" />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BCard, BTab, BTabs } from "bootstrap-vue";
import CodeReviewVuln from "./component/codeReviewVuln.vue";
import UploadCodeReview from "./component/upload.vue";
export default {
  components: {
    BCard,
    BTab,
    BTabs,
    CodeReviewVuln,
    UploadCodeReview,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  created: function () {
    this.setTabIndexFromPathQuery();
  },

  methods: {
    setTabIndexFromPathQuery() {
      if (this.$route.query.tabIndex && this.$route.query.tabIndex != null) {
        this.tabIndex = Number(this.$route.query.tabIndex);
        this.$router.replace({ query: null });
      }
    },
  },
};
</script>