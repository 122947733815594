<template>
  <b-card>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div class="d-flex justify-content-start">
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>

      <div>
        <b-badge class="mr-1" v-if="details.is_verified" variant="success"
          >Verified</b-badge
        >
        <b-badge class="mr-1" v-else variant="danger">Unverified</b-badge>

        <b-badge v-if="details.status == 0" variant="danger">Open</b-badge>
        <b-badge v-if="details.status == 1" variant="success">Closed</b-badge>
        <b-badge v-if="details.status == 2" variant="warning"
          >False Positive</b-badge
        >
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <!-- <b-dropdown-item @click="editIssue(details)">Edit</b-dropdown-item> -->
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 1)"
            >Mark as closed</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 3)"
            >Accept the risk</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status != 0"
            @click="doBulkAction(details, 0)"
            >Re-open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="details.status == 0"
            @click="doBulkAction(details, 2)"
            >Mark as False Positive</b-dropdown-item
          >
          <!-- <div>
            <b-dropdown-item
              v-if="details.asset"
              @click="OpenTaskModal(details.asset.asset_id)"
              >Mitigate</b-dropdown-item
            >
            <b-dropdown-item v-else @click="OpenTaskModal(details.asset_id)"
              >Mitigate</b-dropdown-item
            >
          </div> -->

          <b-dropdown-divider />
          <b-dropdown-item
            v-if="details.is_verified && this.$store.state.app.user.is_staff"
            @click="changeverificationstatus(details, 11)"
            >Mark as un-verified</b-dropdown-item
          >
          <b-dropdown-item
            v-else-if="
              !details.is_verified && this.$store.state.app.user.is_staff
            "
            @click="changeverificationstatus(details, 10)"
            >Mark as verified</b-dropdown-item
          >
          <b-dropdown-item @click="changeverificationstatus(details, 14)"
            >Request for retest</b-dropdown-item
          >
          <b-dropdown-item @click="doBulkAction(details, 9)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <div class="mt-2">
      <h4>{{ details.name }}</h4>
    </div>
    <b-card align="center" class="bg-dark-blue h-100">
      <div class="row">
        <div class="col">
          <b-list-group class="list-group-circle text-left bg-transparent">
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Severity:</b>
              <span class="">
                <span class="">
                  <b-badge
                    style="background-color: #991d28"
                    v-if="details.severity == 'critical'"
                    >Critical</b-badge
                  >
                  <b-badge
                    variant="danger"
                    v-else-if="details.severity == 'high'"
                    >High</b-badge
                  >
                  <b-badge
                    variant="warning"
                    v-else-if="details.severity == 'medium'"
                    >Medium</b-badge
                  >
                  <b-badge
                    variant="primary"
                    v-else-if="details.severity == 'low'"
                    >Low</b-badge
                  >
                  <b-badge
                    variant="success"
                    v-else-if="details.severity == 'info'"
                    >Info</b-badge
                  >
                  <b-badge variant="secondary" v-else>Unknown</b-badge>
                </span>
              </span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Severity Score:</b>

              <span>{{ details.severity_score }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Status:</b>
              <span class="">
                <b-badge variant="danger " v-if="details.status == 0"
                  >Open</b-badge
                >

                <b-badge variant="success" v-else-if="details.status == 1"
                  >Closed</b-badge
                >
                <b-badge variant="warning" v-else-if="details.status == 2"
                  >False Positive</b-badge
                >
                <b-badge variant="success" v-else-if="details.status == 3"
                  >Accepted</b-badge
                >
                <b-badge variant="warning" v-else>{{ details.status }}</b-badge>
              </span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.pci_dss_v3_1 != null"
            >
              <b>PCI DSS V3_1:</b>
              <span class=""> {{ details.pci_dss_v3_1 }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.fisma_2014 != null"
            >
              <b>FISMA 2014: </b>
              <span class="text-capitalize">{{ details.fisma_2014 }} </span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.nist_sp_800_53"
            >
              <b>NIST SP 800.53: </b>
              <span class="text-uppercase">{{ details.nist_sp_800_53 }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.asa_mobile_premium"
            >
              <b>ASA Mobile Premium: </b>
              <span class="text-uppercase">{{
                details.asa_mobile_premium
              }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.asa_premium"
            >
              <b class="mr-1">ASA Premium:</b>
              <span>{{ details.asa_premium }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.asd_stig_5_2"
            >
              <b class="mr-1">ASD STIG 5.2:</b>
              <span>{{ details.asd_stig_5_2 }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent" v-if="details.cwe_top_25">
              <b class="mr-1">CWE Top 25:</b>
              <span>{{ details.cwe_top_25 }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.mois_kisa_secure_coding_2021"
            >
              <b class="mr-1">MOIS(KISA) Secure Coding 2021:</b>
              <span>{{ details.mois_kisa_secure_coding_2021 }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.pci_dss_v3_2_1"
            >
              <b class="mr-1">PCI DSS v3.2.1:</b>
              <span>{{ details.pci_dss_v3_2_1 }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.sans_top_25"
            >
              <b class="mr-1">SANS top 25:</b>
              <span>{{ details.sans_top_25 }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Query Path:</b>
              <span class=""> {{ details.query_path }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.src_file_name"
            >
              <b class="mr-1">Source File Name:</b>
              <span>{{ details.src_file_name }}</span>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent" v-if="details.src_column">
              <b class="mr-1">Source Column:</b>
              <span>{{ details.src_column }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.src_node_id"
            >
              <b class="mr-1">Source NodeId:</b>
              <span>{{ details.src_node_id }}</span>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent" v-if="details.src_name">
              <b class="mr-1">Source Name:</b>
              <span>{{ details.src_name }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.dest_file_name"
            >
              <b class="mr-1">Destination File Name:</b>
              <span>{{ details.dest_file_name }}</span>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent" v-if="details.dest_line">
              <b class="mr-1">Destination Line:</b>
              <span>{{ details.dest_line }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.dest_column"
            >
              <b class="mr-1">Destination Column:</b>
              <span>{{ details.dest_column }}</span>
            </b-list-group-item>
            <b-list-group-item
              class="bg-transparent"
              v-if="details.dest_node_id"
            >
              <b class="mr-1">Destination NodeId:</b>
              <span>{{ details.dest_node_id }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent" v-if="details.dest_name">
              <b class="mr-1">Destination Name:</b>
              <span>{{ details.dest_name }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.result_state"
            >
              <b class="mr-1">Result State:</b>
              <span>{{ details.result_state }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.result_status"
            >
              <b class="mr-1">Result Status:</b>
              <span>{{ details.result_status }}</span>
            </b-list-group-item>

            <b-list-group-item
              class="bg-transparent"
              v-if="details.is_verified"
            >
              <b class="mr-1">Verified:</b>
              <span>{{ details.is_verified }}</span>
            </b-list-group-item>

            <b-list-group-item class="bg-transparent" v-if="details.source">
              <b class="mr-1">Source:</b>
              <span>{{ details.source }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Created At:</b>
              <span>{{ details.created_at | formatdate }}</span>
            </b-list-group-item>
            <b-list-group-item class="bg-transparent">
              <b class="mr-1">Updated At:</b>
              <span>{{ details.updated_at | formatdate }}</span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-card>
    <div v-if="details.description">
      <h5 class="text-primary">Description</h5>
      <p v-html="details.description"></p>
    </div>
    <div>
      <h5 class="text-primary">Owasp Categories</h5>
      <div
        class="mx-1"
        v-for="(owasp, key) in details.owasp_categories"
        :key="key"
      >
        <!-- <h6>{{ index + 1 }}. {{ key }}</h6> -->
        <ul>
          <li>{{ owasp.title }}</li>
        </ul>
      </div>
    </div>

    <!-- {{ details }} -->
  </b-card>
</template>

<script>
import moment from "moment";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BBadge,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },

  data() {
    return {};
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  computed: {
    parsedCompliance() {
      try {
        return JSON.parse(this.details.compliance);
      } catch (e) {
        return {}; // Return an empty object if parsing fails
      }
    },
  },
  created: function () {
    // this.load();
  },

  methods: {
    doBulkAction: function (details, status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: [this.details.id],
      };

      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "code-review/vulns/vuln-bulk-action/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.closeSidebar();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
    changeverificationstatus: function (details, status) {
      var self = this;
      this.loading = true;
      let data = {
        status: status,
        vulnerabilties: [this.details.id],
      };

      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "code-review/vulns/secusy-service-action/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
          self.closeSidebar();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}

.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}

[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
<style scoped>
.custom-list {
  min-height: 150px;
  max-height: 100px;
  overflow-y: auto;
}
.custom-list li:hover {
  color: #7367f0; /* Change the text color to blue on hover */
}
[dir] .list-group-item {
  background-color: #fff0 !important;
}
</style>